export const INDUSTRY = [
  { id: '1', value: 0.576, title: 'Cannabinoid Product Development' },
  { id: '2', value: 0.15, title: 'Cannabis/CBD Testing Labs' },
  { id: '3', value: 0.56, title: 'Cannabis Software' },
  // { id: '4', value: 1.0, title: 'Client-Provided QREs (no haircut)' },
  { id: '5', value: 0.27, title: 'CBD Extraction Companies' },
  { id: '6', value: 0.42, title: 'CBD Product Manufacturing' },
  { id: '7', value: 0.3, title: 'Equipment Manufacturers' },
  { id: '8', value: 0.23, title: 'Hemp Farmers / Growers' },
  { id: '9', value: 0.25, title: 'Genetics & IP Companies' },
  { id: '10', value: 0.21, title: 'Packaging' },
  { id: '11', value: 0.37, title: 'White Label Manufacturing' },
];
