import React, { useEffect } from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import { calculateTaxPerYear } from 'src/helpers/calculateTax';
import styles from './styles.module.scss';

const Industry = (props: any) => {
  useEffect(() => {
    const { listExpense, listStates, industry } = props.pageData || {};
    // const finalTax = calculateTax(listExpense, listStates, industry);
    const { finalTaxYear, finalTax } = calculateTaxPerYear(
      listExpense,
      listStates,
      industry
    );

    setTimeout(() => {
      props.goToNextStep({
        finalTax,
        finalTaxYear,
        listExpense,
        listStates,
        industry,
      });
    }, 3500);
  }, []);

  const imageLoading =
    'https://uploads-ssl.webflow.com/5feadce1361e250be809c3c5/60af5be0a2ede9ab846c9d93_ht-loading-icon.gif';

  return (
    <Container className={styles.container}>
      <Grid centered className={styles.formContainer}>
        <Grid.Row centered columns={1} className={styles.header}>
          <div className={styles.center}>
            <Logo width={130} className={styles.logo} />
            {/* <Header size="large" className={styles.title}>
              Calculating Your Reward
            </Header>
            <div className={styles.subTitle}>
              One moment while we calculate your R&D Tax Credit estimate.
            </div> */}
          </div>
          <div className={styles.backButton}>&nbsp;</div>
        </Grid.Row>
        <Grid.Row className={styles.loading}>
          <img width={200} height={200} src={imageLoading} alt="loading..." />
          {/* <ReactPlayer width={200} muted autoPlay playing url={videoUrl} /> */}
        </Grid.Row>
        <Grid.Row>
          <div className={styles.footer}>
            The information provided by the HempTax Estimator is for
            demonstrative purposes only. The accuracy and results are not
            guaranteed and are subject to change.
          </div>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
export default Industry;
