import React from 'react';
import { Grid, Header, Container } from 'semantic-ui-react';
import { Formik, Form } from 'formik';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
} from 'react-share';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import { ReactComponent as EmailShare } from 'src/assets/icons/email.svg';
import { ReactComponent as Link } from 'src/assets/icons/url-link.svg';
import styles from './styles.module.scss';
import copyIcon from 'src/assets/icons/copyLink.svg';
import backIcon from 'src/assets/icons/back.svg';

const Thanks = (props: any) => {
  const shareUrl = 'https://www.hemptaxcredit.com/hemp-rd-tax-calculator';
  const shareTitle =
    'Check out this R&D Tax Credit Calculator I found on hemptaxcredit.com.';
  const copyToClipBoard = async (copyMe = '') => {
    try {
      await navigator.clipboard.writeText(copyMe);
      // alert('Copied!');
    } catch (err) {
      console.log('Copy fail');
    }
  };
  return (
    <>
      <Container className={styles.container}>
        <Formik
          initialValues={{}}
          onSubmit={() => {
            return;
          }}
        >
          {() => (
            <Form>
              <Grid centered className={styles.formContainer}>
                <Grid.Row centered columns={1} className={styles.header}>
                  <div
                    className={styles.backButton}
                    onClick={() => props.goToInitStep()}
                  >
                    <img src={backIcon} className={styles.icon} />
                    <span className={styles.backText}>Restart Calculator</span>
                  </div>
                  <div className={styles.center}>
                    <Logo width={130} className={styles.logo} />
                  </div>
                  <div className={styles.backButton}>&nbsp;</div>
                </Grid.Row>
                <Grid.Row className={styles.headerTextContainer}>
                  <Header size="large" className={styles.title}>
                    We’ve sent your report!
                  </Header>
                </Grid.Row>
                <Grid.Row className={styles.subTitleSection}>
                  <Header size="large" className={styles.subTitle}>
                    <span className={styles.subTitleShare}>
                      SHARE THIS CALCULATOR
                    </span>
                    <br />
                    <span className={styles.subTitleShareText}>
                      WITH YOUR SOCIAL NETWORK
                    </span>
                  </Header>
                </Grid.Row>
                <Grid.Row className={styles.linkContainerRectangle}>
                  <button
                    value="CopyUrl"
                    type="button"
                    onClick={() => copyToClipBoard(shareUrl)}
                    className={styles.copySvgIconButton}
                  >
                    <ReactTooltip
                      id="linkCopyInput"
                      type="light"
                      event="click"
                      place="left"
                      effect="solid"
                      delayHide={2000}
                      afterShow={() => {
                        copyToClipBoard(shareUrl);
                        ReactTooltip.hide();
                      }}
                    >
                      <span>Link Copied</span>
                    </ReactTooltip>
                    <img
                      src={copyIcon}
                      data-tip
                      data-for="linkCopyInput"
                      className={styles.copySvgIcon}
                    />
                  </button>
                  <span className={styles.shareUrlText}>
                    www.hemptaxcredit.com/hemp-rd-tax-calculator
                  </span>
                </Grid.Row>
                <Grid.Row centered className={styles.form}>
                  <div className={styles.shareButton}>
                    <FacebookShareButton url={shareUrl} quote={shareTitle}>
                      <FacebookIcon size={48} round={true} />
                    </FacebookShareButton>
                  </div>
                  <div className={styles.shareButton}>
                    <TwitterShareButton url={shareUrl} title={shareTitle}>
                      <TwitterIcon size={48} round={true} />
                    </TwitterShareButton>
                  </div>
                  <div className={styles.shareButton}>
                    <LinkedinShareButton
                      url={shareUrl}
                      title={shareTitle}
                      summary={shareTitle}
                    >
                      <LinkedinIcon size={48} round={true} />
                    </LinkedinShareButton>
                  </div>
                  <div className={styles.shareButton}>
                    <EmailShareButton url={shareUrl} body={shareTitle}>
                      <div className={styles.emailButton}>
                        <EmailShare width={22} />
                      </div>
                    </EmailShareButton>
                  </div>
                  <div className={styles.shareButton}>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => copyToClipBoard(shareUrl)}
                    >
                      <ReactTooltip
                        id="linkCopyShare"
                        type="light"
                        event="click"
                        place="bottom"
                        effect="solid"
                        delayHide={2000}
                        afterShow={() => {
                          copyToClipBoard(shareUrl);
                          ReactTooltip.hide();
                        }}
                      >
                        <span>Link Copied</span>
                      </ReactTooltip>
                      <div
                        className={styles.hyperlinkButton}
                        data-tip
                        data-for="linkCopyShare"
                      >
                        <Link width={22.47} />
                      </div>
                    </div>
                  </div>
                </Grid.Row>
                <Grid.Row className={styles.noPadding}>
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    largeScreen={8}
                    widescreen={8}
                    computer={8}
                    className={styles.noPadding}
                  >
                    <Header size="large" className={styles.sendCopy}>
                      <span className={styles.sendCopyText}>
                        READY TO <br />
                        LEARN MORE?
                      </span>
                      <br />
                      <span className={styles.reportText}>
                        LET’S FIND A TIME FOR YOU TO CHAT WITH THE HEMPTAX TEAM
                      </span>
                    </Header>
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    largeScreen={8}
                    widescreen={8}
                    computer={8}
                    className={styles.teamButton}
                  >
                    <button
                      className={styles.buttonSubmitReport}
                      type="submit"
                      onClick={() => {
                        if ((window as any).Calendly) {
                          (window as any).Calendly.initPopupWidget({
                            url:
                              'https://calendly.com/strike-tax-credits/hemptaxcredits-meeting',
                          });
                          return false;
                        }
                      }}
                    >
                      Schedule a Meeting
                    </button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <div className={styles.footer}>
                    The information provided by the HEMP Estimator is for
                    demonstrative purposes only. The accuracy and results are
                    not guaranteed and are subject to change.
                  </div>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};
export default Thanks;
