import React, { useEffect, Suspense } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import qs from 'query-string';
import routes from 'src/routes';

// Keeping this css here because we are loading Main component using lazy and we want to download styles only when component loads
// import 'semantic-ui-css/semantic.min.css';
// WE NEED TO USE SEMANTIC CSS FROM ASSETS AS WE ARE JUST APPLYING SEMANTIC STYLES TO "#strike-tax-tool" ID ELEMENT AND WE DONT WANT TO OVERRIDE WEBFLOW STYLES
import 'src/assets/css/semantic/semantic.scss';
import 'react-vertical-timeline-component/style.min.css';
import 'src/styles/index.scss';

const Root = () => {
  /* eslint-disable */
  useEffect(() => {
    const parsed = qs.parse(window.location.search);
    const {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    } = parsed;
    if (
      !utm_source &&
      !utm_campaign &&
      !utm_content &&
      !utm_medium &&
      !utm_term
    ) {
      // if none of utm tag is set then reset localstorage
      localStorage.removeItem('utm_tags');
      return;
    }
    // set utm_tags
    localStorage.setItem(
      'utm_tags',
      JSON.stringify({
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
      })
    );
  }, []);
  /* eslint-enable */
  // const loading = <InitialLoading />;

  return (
    <div className="app-container test20">
      <Suspense fallback={null}>
        <BrowserRouter>
          <Switch>{renderRoutes(routes)}</Switch>
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default Root;
