import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { Grid, Header, Container } from 'semantic-ui-react';
import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import { List } from 'semantic-ui-react';
import { INDUSTRY } from '../../constants/industry';
import backIcon from 'src/assets/icons/back.svg';
import checkIcon from 'src/assets/icons/check.svg';
import styles from './styles.module.scss';

const Industry = (props: any) => {
  const [industry, setIndustry] = useState(INDUSTRY[0]);
  const handleSubmit = () => {
    ReactGA.event({
      category: 'Calculator',
      action: 'Industry Submit',
    });
    props.goToNextStep({
      industry,
    });
    // here we dont want to clear utm_tags from localstorage,
    // we will clear utm_tags once user submit the email in last step
    localStorage.removeItem('expenses');
    localStorage.removeItem('states');
  };

  return (
    <Container className={styles.container}>
      <Grid centered className={styles.formContainer}>
        <Grid.Row centered columns={1} className={styles.header}>
          <div
            className={styles.backButton}
            onClick={() => props.goToPrevStep()}
          >
            <img src={backIcon} className={styles.icon} />
            {/* <span className={styles.backText}>Go back</span> */}
          </div>
          <div className={styles.center}>
            <Logo width={130} className={styles.logo} />
          </div>
          <div className={styles.backButton}>&nbsp;</div>
        </Grid.Row>
        <Grid.Row className={styles.headerTitle}>
          <div className={styles.center}>
            <Header size="large" className={styles.title}>
              R&D Tax Calculator
            </Header>
            <div className={styles.subTitle}>
              What industry does your company support?
            </div>
          </div>
        </Grid.Row>
        <Grid.Row>
          <List className={styles.listContainer}>
            {INDUSTRY.map((item: any, index: number) => {
              const selectedStyle =
                item.id === industry.id
                  ? styles.listItemSeleted
                  : styles.listItem;

              return (
                <List.Item
                  key={index}
                  className={selectedStyle}
                  onClick={() => setIndustry(item)}
                >
                  <List.Content className={styles.listContent}>
                    <span className={styles.itemTitle}>{item.title}</span>
                    {item.id === industry.id && (
                      <img src={checkIcon} className={styles.checkIcon} />
                    )}
                  </List.Content>
                </List.Item>
              );
            })}
          </List>
        </Grid.Row>
        <div>
          <button
            className={styles.buttonSubmit}
            type="submit"
            onClick={handleSubmit}
          >
            Next
          </button>
        </div>
      </Grid>
    </Container>
  );
};
export default Industry;
