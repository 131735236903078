import React, { useState } from 'react';
import ReactGA from 'react-ga';
import numeral from 'numeral';
import map from 'lodash/map';
import { Grid, Header, Container } from 'semantic-ui-react';
import { Formik, Form, Field } from 'formik';
import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import * as yup from 'yup';
import backIcon from 'src/assets/icons/back.svg';
import styles from './styles.module.scss';
import Thanks from 'src/pages/Thanks';

function getCookie(name) {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(';');
  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');
    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }
  // Return null if not found
  return null;
}

const Result = (props: any) => {
  const {
    finalTax = {},
    finalTaxYear = {},
    listExpense = {},
    listStates = [],
    industry = {},
  } = props.pageData || {};

  const [isSubmit, setSubmit] = useState(false);
  const [isDone, setDone] = useState(false);
  const userSchema = yup.object().shape({
    // fullname: yup.string().required(),
    email: yup.string().email().required(),
    // company: yup.string().required(),
  });

  const utmTagStr = localStorage.getItem('utm_tags');
  let utmTags = {} as any;
  if (!!utmTagStr) {
    utmTags = JSON.parse(utmTagStr);
  }

  const imageLoading =
    'https://uploads-ssl.webflow.com/5feadce1361e250be809c3c5/60af5be0a2ede9ab846c9d93_ht-loading-icon.gif';

  const onSubmit = (values) => {
    const { email } = values;
    // Check script loaded in IFRAME or injected directly in website
    const submitter = window.self !== window.top ? window.top : window;
    const referralCode = getCookie('referral_code');
    const postData = {
      method_name: 'submit_tax_calculator',
      rest_data: {
        name_value_list: {
          data: {
            attributes: {
              referral_code: referralCode,
              email,
              state: listStates.map((item: any) => item.title).join(','),
              years: listExpense,
              industry: industry.title,
              utm_tags: [
                utmTags.utm_source,
                utmTags.utm_medium,
                utmTags.utm_campaign,
                utmTags.utm_term,
                utmTags.utm_content,
              ],
              potential_tax_credit: {
                min: Number(
                  (finalTax.rangeStartMinus || '').replaceAll(',', '')
                ),
                max: Number((finalTax.rangeEndMinus || '').replaceAll(',', '')),
              },
              associated_entity: 'Hemp Tax Credits',
              yearly_tax_credits: finalTaxYear
                .filter((item) => item.year !== 'TOTAL')
                .map((item) => ({
                  year: item.year,
                  federal: Number((item.fedResult || '').replaceAll(',', '')),
                  state: Number((item.avg || '').replaceAll(',', '')),
                  total: Number((item.totalYear || '').replaceAll(',', '')),
                })),
            },
          },
        },
      },
    };

    // console.log('window.self', window.self);
    // console.log('window.top', window.top);
    // console.log('submitter', submitter);
    if (submitter) {
      const msg = JSON.stringify({
        contact: {
          // fullname,
          email,
          // company,
        },
        years: listExpense,
        states: listStates.map((item: any) => item.title).join(','),
        industry: industry.title,
        amount: `${finalTax.rangeStartMinus} - ${finalTax.rangeEndMinus}`,
      });
      console.log('posting message to webflow ', msg);

      submitter.postMessage(msg, '*');
    }

    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const requestOptions: any = {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(postData),
      redirect: 'follow',
    };

    const url = `${process.env.REACT_APP_CRM_URL}/tax-calculator`;
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log('error', error));
    ReactGA.event({
      category: 'submissions',
      action: 'Form Submission',
    });

    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
      setDone(true);
      localStorage.removeItem('utm_tags');
      // props.history.push('/thanks');
    }, 1500);
  };

  const formatNum = (num) => {
    return numeral(num).format('0.0a');
  };

  if (isDone) {
    return <Thanks {...props} />;
  }

  return (
    <Container className={styles.container}>
      <Formik
        initialValues={{
          // fullname: '',
          email: '',
          // company: '',
        }}
        validationSchema={userSchema}
        onSubmit={onSubmit}
      >
        {({ errors, dirty, isValid, touched }) => {
          const isDisabled = !dirty || !isValid;
          const btnSubmitStyles = isDisabled
            ? styles.buttonSubmitDisabled
            : styles.buttonSubmit;
          return (
            <Form
              id="hemp-calculator"
              className={isDone ? styles.formBlur : ''}
            >
              <Grid centered className={styles.formContainer}>
                <Grid.Row centered columns={1} className={styles.header}>
                  <div
                    className={styles.backButton}
                    onClick={() => props.goToInitStep()}
                  >
                    <img src={backIcon} className={styles.icon} />
                    {/* <span className={styles.backText}>Restart Calculator</span> */}
                  </div>
                  <div className={styles.center}>
                    <Logo width={130} className={styles.logo} />
                  </div>
                  <div className={styles.backButton}>&nbsp;</div>
                </Grid.Row>
                <Grid.Row className={styles.sectionTitle}>
                  <Header size="large" className={styles.title}>
                    Estimated R&D Tax Credit
                  </Header>
                </Grid.Row>
                <Grid.Row centered className={styles.content}>
                  <span className={styles.rangeStartMinus}>
                    <sup className={styles.sup}>$</sup>
                    {formatNum(finalTax.rangeStartMinus)}
                  </span>{' '}
                  <span className={styles.rangeTo}>to</span>
                  <span className={styles.rangeEndMinus}>
                    <sup className={styles.sup}>$</sup>
                    {formatNum(finalTax.rangeEndMinus)}
                  </span>
                </Grid.Row>
                {isSubmit ? (
                  <Grid.Row centered className={styles.loadingWrapper}>
                    <img
                      width={200}
                      height={200}
                      src={imageLoading}
                      alt="loading..."
                      style={{ marginTop: 25, marginBottom: 25 }}
                    />
                    {/* <ReactPlayer
                      width={100}
                      height={100}
                      muted
                      playing
                      url={videoUrl}
                    /> */}
                  </Grid.Row>
                ) : (
                  <Grid.Row centered className={styles.form}>
                    <table className={styles.tableSection}>
                      <tr className={styles.tableHeader}>
                        <th>&nbsp;</th>
                        <th>FEDERAL</th>
                        <th>AVG STATE</th>
                        <th>YEARLY TOTAL</th>
                      </tr>
                      {map(finalTaxYear, (item) => {
                        if (item.year === 'TOTAL') {
                          return (
                            <tr key={item.year}>
                              <td>TOTAL</td>
                              <td>${formatNum(item.fedResult)}</td>
                              <td>${formatNum(item.avg)}</td>
                              <td className={styles.cellTotal}>
                                ${formatNum(item.totalYear)}
                              </td>
                            </tr>
                          );
                        }
                        return (
                          <tr key={item.year}>
                            <td className={styles.cellYear}>{item.year}</td>
                            <td>${formatNum(item.fedResult)}</td>
                            <td>${formatNum(item.avg)}</td>
                            <td>${formatNum(item.totalYear)}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </Grid.Row>
                )}
                <Grid.Row className={styles.sendCopySection}>
                  <Header size="large" className={styles.sendCopy}>
                    <span className={styles.sendCopyText}>
                      Get an Accurate Estimate
                    </span>
                    <br />
                    <span className={styles.reportText}>
                      and talk with an member of the HempTax team
                    </span>
                  </Header>
                </Grid.Row>
                {!isDone && (
                  <Grid.Row className={styles.noPadding}>
                    <Grid.Column
                      width={8}
                      mobile={16}
                      tablet={8}
                      largeScreen={8}
                      widescreen={8}
                      computer={8}
                      className={styles.noPadding}
                    >
                      <ul className={styles.listBenefit}>
                        <li>
                          <span className="fa-li">
                            <i className="fas fa-check-square"></i>
                          </span>
                          Unlimited Audit Support
                        </li>
                        <li>Money Back Guarantee</li>
                        <li>No Upfront Costs</li>
                      </ul>
                    </Grid.Column>
                    <Grid.Column
                      width={8}
                      mobile={16}
                      tablet={8}
                      largeScreen={8}
                      widescreen={8}
                      computer={8}
                      className={styles.sendReportInputWrapper}
                    >
                      <div>
                        <Field
                          name="email"
                          type="text"
                          autoComplete="off"
                          className={
                            touched.email && errors.email
                              ? styles.input
                              : touched.email
                              ? styles.inputActive
                              : styles.input
                          }
                          placeholder="Email Address"
                        />
                      </div>
                      <div>
                        {!isDone && (
                          <button
                            disabled={isDisabled}
                            className={btnSubmitStyles}
                            type="submit"
                          >
                            Send me the Report
                          </button>
                        )}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                )}
                {!isDone && (
                  <Grid.Row>
                    <div className={styles.footer}>
                      The information provided by the HEMP Estimator is for
                      demonstrative purposes only. The accuracy and results are
                      not guaranteed and are subject to change.
                    </div>
                  </Grid.Row>
                )}
                {/* Google Tag Manager */}
                {/* <input
                  type="hidden"
                  name="Year2017"
                  value={listExpense['2017']}
                  id="year2017"
                /> */}
                <input
                  type="hidden"
                  name="Year2019"
                  value={listExpense['2019']}
                  id="year2019"
                />
                <input
                  type="hidden"
                  name="Year2020"
                  value={listExpense['2020']}
                  id="year2020"
                />
                <input
                  type="hidden"
                  name="Year2021"
                  value={listExpense['2021']}
                  id="year2021"
                />
                <input
                  type="hidden"
                  name="Year2022"
                  value={listExpense['2022']}
                  id="year2022"
                />
                <input
                  type="hidden"
                  name="Year2023"
                  value={listExpense['2023']}
                  id="year2023"
                />
                <input
                  type="hidden"
                  name="States"
                  value={listStates.map((item: any) => item.title).join(',')}
                  id="states"
                />
                <input
                  type="hidden"
                  name="Industry"
                  value={industry.title}
                  id="industry"
                />
                <input
                  type="hidden"
                  name="Amount"
                  value={`${finalTax.rangeStartMinus} - ${finalTax.rangeEndMinus}`}
                  id="amount"
                />
                {/* Google Tag Manager */}
                {/* {!isDone && (
                  <div className={styles.footer}>
                    <button
                      disabled={isDisabled}
                      className={btnSubmitStyles}
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                )} */}
                <div>
                  <button
                    disabled={isDisabled}
                    className={
                      isDisabled
                        ? styles.mobileButtonSubmitDisabled
                        : styles.mobileButtonSubmit
                    }
                    type="submit"
                  >
                    Send me the Report
                  </button>
                </div>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
export default Result;
